<template>
  <keep-alive :include="$store.state.includes">
    <router-view></router-view>
  </keep-alive>
</template>

<script>
export default {};
</script>

<style></style>
